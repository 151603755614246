import Time from "../time/time";

function GameInfo() {
  return (
    <aside>
      <Time />
    </aside>
  );
}

export default GameInfo;
